
<template>
  <div class="informationList">
    <div class="cont" v-if="list.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="init"
      >
        <el-row
          v-for="(li, i) in list"
          :key="i"
          type="flex"
          justify="space-between"
          align="top"
          @click.native="handleTo(li)"
        >
          <el-col>
            <img v-lazy="li.CoverImg" alt="" />
          </el-col>
          <el-col>
            <div class="til">{{ li.Title }}</div>
            <div class="time">
              <span>{{ li.CreateTime | dataYMD }}</span>
              <span>{{ li.ReadCount }}人看过</span>
            </div>
          </el-col>
        </el-row>
      </van-list>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="24">
          <no-data></no-data>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { queryArtGalleryNews, updateNewsReadCount } from "@/api/fineArts";
import noData from "@/components/noData";
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      tabs: [],
      loading: false,
      finished: false,
      route: {},
      typeId: 0,
    };
  },
  components: {
    noData,
  },
  created () { },
  mounted () {
    this.route = this.$route.query;

    this.init();
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        this.postUpdateNewsReadCount(obj.Id)
        window.open(obj.JumpUrl);
      } else {
        let path = "/H5/informationListDetails";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    async init () {
      let parm = {
        type: 1,
        CategoryId: this.route.categoryId,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await queryArtGalleryNews(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.informationList {
  min-height: 100vh;
  background-color: #fff;
  .top {
    padding: 0 15px;
    background-color: #fff;
    color: #5b5b5b;
    .li_box {
      display: flex;
    }
    span {
      display: inline-block;
      min-width: 70px;
      text-align: center;
      margin-right: 10px;
      line-height: 60px;
      cursor: pointer;
    }
    .active {
      color: #1288f4;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 3px;
        background-color: #1288f4;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .cont {
    padding: 0 15px;
    .el-row {
      padding: 10px 0;
      .el-col {
        &:first-child {
          width: 146px;
          flex: 0 0 146px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 90px;
            border-radius: 6px;
          }
        }
        &:last-child {
          display: flex;
          align-content: space-between;
          flex-wrap: wrap;
          .til {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.5;
            font-size: 15px;
            font-weight: bold;
            word-break: break-all;
            width: 100%;
          }
          .time {
            font-size: 13px;
            color: #999999;
            line-height: 1.5;
            display: flex;
            justify-content: space-between;
            width: 100%;
            // span{display: inline-block;width: 50%;}
          }
        }
      }
    }
  }
}

/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
</style>